
import ProductCard from "~/components/common/ProductCard";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import UiSwiperCarousel from "~/components/UI/UiSwiperCarousel.vue";
export default {
  name: "BestsellerBlock",
  components: { UiSwiperCarousel, ProductCard },
  mixins: [googleAnalyticsEvents],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mapedData() {
      return this.blockData.map((el) => ({
        ...el,
        image: el?.images?.[0]?.url,
      }));
    },
    canSendGoogleAnalyticsStatistic() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        this.mapedData?.length
      );
    },
    statisticItemListName() {
      return this.pageData?.mixedTranslation?.title || this.data?.title;
    },
  },
  watch: {
    canSendGoogleAnalyticsStatistic: {
      handler() {
        this.gaViewItemListEvent(this.mapedData, {
          item_list_name: this.statisticItemListName,
        });
      },
      immediate: true,
    },
  },
};
